import React from 'react';

const ComparisonGraph = () => {
    const data = [
        {
            height: 35,
            color: '#FFB74D',
            title: 'Lost Revenue from No Shows'
        },
        {
            height: 50,
            color: '#FB8C00',
            title: 'Time Wasted on Appointment Management'
        },
        {
            height: 70,
            color: '#F4511E',
            title: 'Missed Opportunities'
        },
        {
            height: 100,
            color: '#D32F2F',
            title: 'Costly Payment Devices'
        },
        {
            height: 70,
            color: '#F4511E',
            title: 'Business Performance Uncertainty'
        },
        {
            height: 50,
            color: '#FB8C00',
            title: 'Manual Scheduling Hassles'
        },
        {
            height: 35,
            color: '#FFB74D',
            title: 'Customer Communication Gaps'
        }
    ];

    const getLineWidth = (index) => {
        const maxWidth = 160;
        const minWidth = 60;
        const widthStep = (maxWidth - minWidth) / 3;
        const midpoint = Math.floor(data.length / 2);

        if (index < midpoint) {
            return minWidth + ((midpoint - index) * widthStep);
        } else if (index > midpoint) {
            return minWidth + ((index - midpoint - 1) * widthStep);
        }
        return 0;
    };

    return (
        <div className="container-fluid pb-4">
            <h2 className='sec_heading text-center mb-5 pb-md-0 pb-4'>Pains You
                Leave Behind with
                Chuzeday</h2>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                    <div className="d-flex flex-column align-items-center">
                        <div
                            className="d-flex align-items-end justify-content-center w-100 position-relative comparison_graph"
                            style={{
                                height: '500px',
                                padding: '40px 120px',
                                backgroundColor: 'white'
                            }}
                        >
                            {data.map((item, index) => {
                                const midpoint = Math.floor(data.length / 2);
                                const isMiddle = index === midpoint;
                                const isLeftSide = index < midpoint;
                                const lineWidth = getLineWidth(index);

                                return (
                                    <div
                                        key={index}
                                        className="position-relative h-100"
                                        style={{
                                            width: '60px',
                                            marginLeft: index === 0 ? 0 : '10px'
                                        }}
                                    >
                                        {/* Vertical bar */}
                                        <div
                                            className="position-absolute bottom-0 w-100"
                                            data-aos="fade-up"
                                            style={{
                                                height: `${item.height}%`,
                                                backgroundColor: item.color
                                            }}
                                        />

                                        {!isMiddle && (
                                            <div
                                                className="position-absolute w-100"
                                                style={{
                                                    top: `${100 - item.height}%`,
                                                    height: '2px'
                                                }}
                                            >
                                                {/* Horizontal line */}
                                                <div
                                                    className="position-absolute"
                                                    style={{
                                                        height: '2px',
                                                        backgroundColor: item.color,
                                                        ...(isLeftSide ? {
                                                            right: '0',
                                                            width: '100%',
                                                            transform: 'translateX(-100%)'
                                                        } : {
                                                            left: '0',
                                                            width: '100%',
                                                            transform: 'translateX(100%)'
                                                        })
                                                    }}
                                                >
                                                    {/* Dot and text container */}
                                                    <div className="position-relative" style={{ width: '100%' }}>
                                                        {/* Dot */}
                                                        <div
                                                            className="position-absolute rounded-circle"
                                                            style={{
                                                                width: '8px',
                                                                height: '8px',
                                                                backgroundColor: item.color,
                                                                top: '-3px',
                                                                ...(isLeftSide ? {
                                                                    left: '0',
                                                                    transform: 'translateX(-4px)'
                                                                } : {
                                                                    right: '0',
                                                                    transform: 'translateX(4px)'
                                                                })
                                                            }}
                                                        />

                                                        {/* Title positioned after dot */}
                                                        <div
                                                            className="position-absolute text-nowrap"
                                                            style={{
                                                                fontSize: '15px',
                                                                color: '#333',
                                                                top: '-10px',
                                                                ...(isLeftSide ? {
                                                                    left: '-8px',
                                                                    transform: 'translateX(-100%)',
                                                                    marginLeft: '-8px',
                                                                    textAlign: 'right'
                                                                } : {
                                                                    right: '-8px',
                                                                    transform: 'translateX(100%)',
                                                                    marginRight: '-8px',
                                                                    textAlign: 'left'
                                                                })
                                                            }}
                                                        >
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Middle bar title */}
                                            {isMiddle && (
                                                <div
                                                    className="position-absolute mdl_bar"
                                                    style={{
                                                        top: `${100 - item.height}%`,
                                                        left: '50%',
                                                        transform: 'translate(-50%, -32px)',
                                                        fontSize: '15px',
                                                        color: '#333',
                                                        width: 'auto',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {item.title}
                                                </div>
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComparisonGraph;