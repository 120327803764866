import React, { useEffect, useState } from 'react';
import { PAGE_VIDEOS } from '../../../gql/Query';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

const Hero = () => {
    const [video, setVideo] = useState({})
    console.log("🚀 ~ DownloadApp ~ video:", video)
    const { data: page_videos, error: page_videos_error } = useQuery(PAGE_VIDEOS, {
        variables: {
            page: "home"
        }
    })
    useEffect(() => {
        if (page_videos) {
            console.log("🚀 ~ DownloadApp ~ page_videos:", page_videos)
            setVideo(page_videos.page_videos)
        }
    }, [page_videos])
    return (
        <div className="container py-5 my-5 how_works">
            <div className="row align-items-center gx-4">
                <div className="col-md-7">
                    <div className="video-container">
                        <iframe
                            id="youtube-player"
                            className="w-100"
                            style={{ aspectRatio: '16/9' }}
                            src={`https://www.youtube.com/embed/iUseITmRT-I?autoplay=0&mute=0&loop=1&playlist=iUseITmRT-I&amp;&controls=1`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <h3 className="display-6 mt-3 text-center">Deborah Jordan CEO</h3>
                </div>
                <div className="col-md-5">
                    <div className="p-4">
                        <h2 className="display-5 mb-5 text-center">
                            Welcome, lets
                            watch a video from
                            Deborah and learn
                            how Chuzeday
                            automates your
                            Individual business
                            </h2>
                        <Link to={'/system/book-a-demo'} className="btn btn-dark btn-lg rounded-pill round_btn mx-auto d-block btn-warning">
                            Book A
                            Discovery Call
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;