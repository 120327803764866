import React, { useEffect, useState } from 'react';
import { PAGE_VIDEOS } from '../../../gql/Query';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
const HowSetup = () => {
      const [video, setVideo] = useState({})
      console.log("🚀 ~ DownloadApp ~ video:", video)
      const { data: page_videos, error: page_videos_error } = useQuery(PAGE_VIDEOS, {
          variables: {
              page: "home"
          }
      })
      useEffect(() => {
          if (page_videos) {
              console.log("🚀 ~ DownloadApp ~ page_videos:", page_videos)
              setVideo(page_videos.page_videos)
          }
      }, [page_videos])
  return (
    // <div className='container-fluid px-0 text-center' data-aos="zoom-in" >
    //       <img
    //           src={howWork}
    //           alt="How easy it to setup"
    //           className="img-fluid mx-auto"
    //       />
    //     <h6 className="display-6 fw-medium my-4 text-center">We've streamlined the process to get your business up and running with Chuzeday</h6>
    //     <Link to="/system/book-a-demo">
    //       <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn">
    //         BOOK A DEMO
    //       </button>
    //     </Link>
    // </div>
    <div className="container py-5 my-5 how_works">
      <div className="row align-items-center gx-4">
        <div className="col-md-7">
          <div className="video-container">
            <iframe
              id="youtube-player"
              className="w-100"
              style={{ aspectRatio: '16/9' }}
              src={`https://www.youtube.com/embed/iUseITmRT-I?autoplay=0&mute=0&loop=1&playlist=iUseITmRT-I&amp;&controls=1`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <h3 className="display-6 mt-3 text-center">Deborah Jordan CEO</h3>
        </div>
        <div className="col-md-5">
          <div className="p-4 how_set_text">
            <h2 className="sec_heading mb-3">
              How easy is it to set up
            </h2>
            <p className=''>
              <strong>Step 1:</strong> Learn what Chuzeday is all about—explore our Blogs, eBook, and bonus materials.
              Get inspired and see how we’re empowering businesses like yours!
            </p>
            <p className=''>
              <strong>Step 2:</strong>Book a quick 15-minute Discovery Call with our team.
              We’ll Walk you through how Chuzeday works and get to know your business needs.
            </p>
            <p className=''>
              <strong>Step 3:</strong>You’ll move to a 30-minute Demo Call where we’ll show you—step by step—how Chuzeday
              tackles your biggest challenges.
            </p>
            <p>From there? You choose:</p>
            <ul>
              <li>✅ Set up your account yourself, or</li>
              <li>✅ Let us do it with you, fast and easy.</li>
            </ul>
            <p>Your business could be live on Chuzeday within hours of subscribing!</p>
            <Link to={'/system/book-a-demo'} className="btn btn-dark btn-lg rounded-pill round_btn mx-auto d-block">
              Book A
              Discovery Call
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowSetup
