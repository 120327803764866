import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import MetaGenerator from '../../widgets/MetaGenerator';
import Loader from '../Loader';
import BusinessAction from '../../widgets/CallToAction/BusinessAction';

//API
import { BLOGS } from '../../../../gql/Query';
import RevolutAffiliateBanner from '../../Home/revolutAffiliateBanner/RevolutAffiliateBanner';
import BonusLink from '../../widgets/CallToAction/BonusLink';

const Blog = () => {
    const imageBaseURL = "https://chuzeday.com/";
    const maxLength = 300;
    const [blog, setBlog] = useState();
    const { data, loading, error } = useQuery(BLOGS);

    //----------------------html to string-------------------
    const extractContent = (text, slug) => {
        const span = document.createElement('span');
        span.innerHTML = text;
        if (span.textContent?.length > maxLength) {
            return <p>{`${span.textContent?.substring(0, maxLength)}...`} <Link to={`/system/blog/${slug}`}>See More</Link> </p>
        } else {
            return <p> {span.textContent} </p>
        }

    };

    useEffect(() => {
        if (data) {
            setBlog(data?.blogs)
            //console.log("data", data)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <>
            <MetaGenerator
                title="Blog"
            />
            <div className="row">
                <div className="col-md-8">
                    <div className="row blog-card">
                        <h1 className='sectionHeading text-center'>Blog</h1>
                        {
                            loading ?
                                <Loader /> :
                                blog?.length > 0 &&
                                blog.map((item) => (
                                    <div className="col-md-6 mb-4" key={item.id}>
                                        <Link to={`/system/blog/${item?.slug}`}>
                                        <Card className='outer-card'>
                                            <Card.Img className="card img-fluid p-0" variant="top" src={`${imageBaseURL}${item?.image}`} />
                                            <Card.Body>
                                                <h2>{item?.title}</h2>
                                                <Card.Text>
                                                    <div> {extractContent(item?.description, item?.slug)} </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        </Link>
                                    </div>
                                ))
                        }
                    </div>
                </div>
                <div className="col-md-4 position-relative">
                    <div className="sticky-action">
                        <BusinessAction />
                        {/* <RevolutAffiliateBanner layout={'blog'} /> */}
                        <BonusLink/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog