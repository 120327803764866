import React, { useState } from 'react'
import MetaGenerator from '../widgets/MetaGenerator'
import ScrollTop from '../widgets/ScrollTop'
import SeconderyHeader from '../Header/SeconderyHeader'
import WhyChoose from '../widgets/whyChoose/WhyChoose'
import Footer from '../Footer/Footer'
import StripePricingTable from '../Home/components/Subscription/SubscriptionTable'
import "dotenv/config";

const StripeSubscriptionPricingTable = () => {
const [subscribePackegId, setSubscribePackegId] = useState(null);
const [amount, setAmount] = useState(0);
const handleSubscriptionSelected = (priceId) => {
//   const subscPack = subscriptionPackeg.find((packeg) => packeg.id == priceId);
//     if (subscPack) {
//         setSubscribePackegId(priceId);
//         handleSubscribe(subscPack);
//     }
};

//    const handleSubscribe = (subscPack) => {
//         setAmount(subscPack?.price);
//         subscribe({
//             variables: {
//             id: +subscPack?.id,
//             price: subscPack?.price,
//             payment_type: "Stripe",
//             },
//         }).then(({ data }) => {
//             const { status, message } = data?.subscribe;
//             if (status === 0) {
//             // showToast(message, "error");
//             }
//             if (status === 1) {
//             window.open(data.subscribe.payment_url, "__blank");
//             }
//         });
//     };

  return (
    <>
      <MetaGenerator title="Pricing Table" />
      <ScrollTop />
      <div id="filterHeader">
        <SeconderyHeader />
      </div>
      <StripePricingTable
        pricingTableId={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
        publishableKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        onSubscriptionSelected={handleSubscriptionSelected}
      />
      <WhyChoose />
      <Footer />
    </>
  );
}

export default StripeSubscriptionPricingTable;
