import { gql } from "@apollo/client";

export const AUTH_LOGIN = gql`
    mutation login(
        $username: String!
        $password: String!
    ){
        login(
            username: $username
            password: $password
        ){
            access_token
            user{
                user_type
                first_name
                last_name
                id
            }
        }     
    }   
`;
export const AUTH_LOGOUT = gql`
    mutation logout{
        logout{
            status
            message
        }
    }   
`;
export const CALENDAR_DISCONNECT = gql`
    mutation disconnectGoogleAccount{
        disconnectGoogleAccount{
            status
            message
        }
    }   
`;
export const GUEST_REGISTRATION = gql`
    mutation guestRegister(
        $first_name: String!
        $last_name: String
        $mobile: String
        $email: String
        $password: String
        $additional_mobile: String
        $client_source: String
        $display_booking: Boolean
        $gender: String
        $dob: String
        $client_info: String
        $address: String
        $email_notification: Boolean
        $marketing_notification: Boolean
        $language: String
        $hash: String!
    ){
        guestRegister(
            first_name: $first_name
            last_name: $last_name
            mobile: $mobile
            email: $email
            password: $password
            additional_mobile: $additional_mobile
            client_source: $client_source
            display_booking: $display_booking
            gender: $gender
            dob: $dob
            client_info: $client_info
            address: $address
            email_notification: $email_notification
            marketing_notification: $marketing_notification
            language: $language
            hash: $hash
        ){
            status
            message
        }
    }
`;

export const PROFILE_UPDATE = gql`
    mutation guestProfileUpdate(
        $first_name: String!
        $last_name: String!
        $mobile: String!
        $email: String!
        $gender: String
        $dob: String
        $client_info: String
        $address: String
        $email_notification: Boolean
        $marketing_notification: Boolean
        $language: String
        $additional_mobile: String
        $client_source: String
        $display_booking: Boolean
        $photo: String
        $suite: String
        $country: String
        $eir_code: String
  ){
    guestProfileUpdate(
        first_name: $first_name
        last_name: $last_name
        mobile: $mobile
        email: $email
        gender: $gender
        dob: $dob
        client_info: $client_info
        address: $address
        email_notification: $email_notification
        marketing_notification: $marketing_notification
        language : $language
        additional_mobile: $additional_mobile
        client_source: $client_source
        display_booking: $display_booking
        photo: $photo
        suite: $suite
        country: $country
        eir_code: $eir_code

    ){
        status
        message
    }
}
`;

export const FORGET_PASSWORD = gql`
    mutation forgotPassword(
       $email:String!
    ){
        forgotPassword(
           email: $email
        ){
            status
            message
        }     
    }   
`;
export const RESET_PASSWORD = gql`
    mutation resetPassword(
        $token: String!
        $email: String!
        $password: String!
        $password_confirmation: String!
    ){
        resetPassword(
            token: $token
            email: $email
            password: $password
            password_confirmation: $password_confirmation
        ){
            status
            message
        }     
    }   
`;
export const ADD_FAVOURITE = gql`
    mutation save_businesses(
        $type: Int!
        $business_id: Int!
    ){
        save_businesses(
            type: $type
            business_id: $business_id
        ){
            status
            message
        }     
    }   
`;
export const SOCIAL_LOGIN = gql`
    mutation socialLogin(
        $provider: String!
        $token: String!
    ){
        socialLogin(
            provider: $provider
            token: $token
        ){
            access_token
            user{
                user_type
                first_name
                last_name
                id
            }
        }     
    }   
`;
export const SOCIAL_REGISTER = gql`
    mutation socialRegister(
        $first_name: String!
        $last_name: String
        $mobile: String
        $email: String
        $password: String
        $provider: String
        $provider_id: String
    ){
        socialRegister(
            first_name: $first_name
            last_name: $last_name
            mobile: $mobile
            email: $email
            password: $password
            provider: $provider
            provider_id: $provider_id
        ){
           status
           message
        }     
    }   
`;
export const BOOK_APPOINTMENT = gql`
    mutation appointment_booking(
        $business_id: Int!
        $services: [BookingServiceInput!]!
        $vouchers: [BookingVoucherInput!]!
        $total_amount: BookingTotalAmountInput!
        $payment_info: BookingPaymentInfoInput!
        $upfront_amount: String!
        $discount: String!
        $video_datetime: String!
        $booking_request: String!
        $google_pay_token: String
    ){
        appointment_booking(
            business_id: $business_id
            services: $services
            vouchers: $vouchers
            total_amount : $total_amount
            payment_info: $payment_info
            upfront_amount: $upfront_amount
            discount: $discount
            video_datetime: $video_datetime
            booking_request: $booking_request
            google_pay_token: $google_pay_token

        ){
           status
           message
           sale_id
           payment_url
           payment_amount
           payment_type
           payment_status
        }     
    }   
`;
export const ADD_REVIEW = gql`
    mutation add_review(
        $appt_id: Int!
        $rating: Float!
        $comment: String
    ){
        add_review(
            appt_id: $appt_id
            rating: $rating
            comment: $comment
        ){
           status
           message
        }     
    }   
`;

export const CHANGE_APPOINTMENT = gql`
    mutation statusUpdateForAppointment(
        $id: ID!
        $status: String!
        $service_pricing_ids: [ID]!
        $date: String
        $time: String
        $note: String
    ){
        statusUpdateForAppointment(
            id: $id
            status: $status
            service_pricing_ids: $service_pricing_ids
            date: $date
            time: $time
            note: $note
        ){
           status
           message
        }     
    }   
`;
export const MAKE_PAYMENT = gql`
    mutation makePayment(
       $sale_id: Int!
       $appt_id: Int!
       $payment_type: String!
       $booking_request: String!
       $google_pay_token: String
    ){
        makePayment(
           sale_id: $sale_id
           appt_id: $appt_id
           payment_type: $payment_type
           booking_request: $booking_request
           google_pay_token: $google_pay_token
        ){
           sale_id
           appt_id
           revolut
           status
           message
           payment_url
           payment_amount
           payment_status
        }     
    }   
`;
export const CANCELLATION_REQUEST = gql`
    mutation cancellation_request(
        $date: String!
        $guest_id: Int
        $business_id: Int
    ){
        cancellation_request(
            date: $date
            guest_id: $guest_id
            business_id: $business_id
        ){
            status
            message
        }     
    }   
`;
export const PRICING_PLAN = gql`
    mutation pricingPlan(
        $name: String!
        $email: String!
        $phone: String!
        $description: String
        $query_type: String
        $referral_code: String
    ){
        pricingPlan(
            name: $name
            email: $email
            phone: $phone
            description: $description
            query_type: $query_type
            referral_code: $referral_code
        ){
            status
            message
        }     
    }   
`;
export const REVOLUT_PUBLIC_ID = gql`
    mutation revolutPaymentPublicId(
        $amount: Float!
        $currency: String!
    ){
        revolutPaymentPublicId(
            amount: $amount
            currency: $currency
        ){
            id
            public_id
        }     
    }   
`;
export const REVOLUT_PAY_CONFIRM = gql`
    mutation revolutPaymentConfirmation(
        $sale_id: Int!
        $order_id: String!
        $appt_id: Int
    ){
        revolutPaymentConfirmation(
            sale_id: $sale_id
            order_id: $order_id
            appt_id: $appt_id
        ){
            status
            message
            payment_url
        }     
    }   
`;
export const REVOLUTE_PAY_FOR_SUBSCRIPTION = gql`
    mutation revolutPaymentForSubscribed(
        $order_id: String!
        $subscribed_id: Float!
    ){
        revolutPaymentForSubscribed(
            order_id: $order_id
            subscribed_id:$subscribed_id          
        ){
            sale_id
            status
            revolut
            message
            payment_url
            subscribed_id
            payment_amount
        }     
    }   
`;
// export const TIME_SLOT_AVAILABILITY = gql`
//     mutation timeslot_availability(
//         $business_id: Int!
//         $services: String!
//     ){
//         timeslot_availability(
//             business_id: $business_id
//             services: $services
//         ){
//            status
//            message
//         }     
//     }   
// `;

export const CLEAR_NOTIFICATION = gql`
    mutation notificationDelete(
        $ids: String!
    ){
        notificationDelete(
            ids: $ids
        ){
           status
           message
        }     
    }   
`;

export const BLOCKED_GUEST_VIDEO = gql`
    mutation video_consultation_booking(
        $video_datetime: String!
        $business_id: Int!
    ){
        video_consultation_booking(
            video_datetime: $video_datetime
            business_id: $business_id
        ){
           status
           message
        }     
    }   
`;

export const CANCEL_FUTURE_APPOINTMENT = gql`
    mutation cancel_future_appointment(
        $business_id: Int!
    ){
        cancel_future_appointment(
            business_id: $business_id
        ){
           status
           message
        }     
    }   
`;
export const CHANGE_PASSWORD = gql`
    mutation updatePassword(
        $old_password: String!
        $password: String!
        $password_confirmation: String!
    ){
        updatePassword(
           old_password: $old_password
           password: $password
           password_confirmation: $password_confirmation
        ){
            status
            message
        }     
    }   
`;
export const GUEST_OFFBOARDING = gql`
    mutation guestOffBoardingRequest(
        $answer: Int!
        $email: String!
    ){
        guestOffBoardingRequest(
           answer: $answer
           email: $email
        ){
            status
            message
        }     
    }   
`;

export const AGORA_ROOM_TOKEN = gql`
mutation agoraAccessToken($room_id: String!){
    agoraAccessToken(room_id: $room_id){
        status
        token
        message
    }
}`;

// payment log mutation
export const SYSTEM_LOG = gql`
    mutation system_log(
        $api: String!
        $user: String!
        $body: String!
        $response: String!
        $exception: String!
        $source: String!
        $version: String!
        $priority: String!
        $device: String!
        $type: String
    ){
        system_log(
            api: $api
            user: $user
            body: $body
            response: $response
            exception: $exception
            source: $source
            version: $version
            priority: $priority
            device: $device
            type: $type
        ){
            status
            message
        }     
    }   
`;

// Campaign Traffic mutation
export const CAMPAIGN_TRAFFIC = gql`
mutation campaignTraffic($referral_code: String!, $page: String!){
    campaignTraffic(referral_code: $referral_code, page: $page){
        status
        message
    }
}
`;
