import React, { useEffect, useState } from 'react'
import MetaGenerator from '../../widgets/MetaGenerator'
import BusinessAction from '../../widgets/CallToAction/BusinessAction'
import { useLazyQuery } from '@apollo/client'
import { FAQS } from '../../../../gql/Query'
import FAQ from './FAQ'
import Loader from '../Loader'
import RevolutAffiliateBanner from '../../Home/revolutAffiliateBanner/RevolutAffiliateBanner'
import Banner from './Banner';
import EBook from '../../Home/components/Ebook/EBook'

const FAQs = () => {
    const [faqs, setFaqs] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchQuery, { loading, data, error }] = useLazyQuery(FAQS);

    useEffect(() => {
        const timer = setTimeout(() => {
            searchQuery({
                variables: {
                    keyword: searchValue
                }
            });
        }, 300); // Debounce time in milliseconds

        return () => {
            clearTimeout(timer);
        };
    }, [searchValue, searchQuery]);

    useEffect(() => {
        if (data) {
            setFaqs(data?.faq_categories)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    
  return (
      <div className='pt-150 font-chzday system faq_contain pb-5'>
      <MetaGenerator
          title="FAQ"
      />
      <Banner/>
      <div className='container mb-5'>
          <div className="row">
              <div className="col-md-8">
                  <div>
                      <div className='faq-static-section  col-md-12 col-12'>
                          <h2 className="sectionHeading">FAQ's</h2>
                          <div className="faq-search-inner">
                              <input
                                  aria-controls="sticky-search-collapse1"
                                  type="search"
                                  placeholder="Search FAQ's"
                                  className="service-input"
                                  value={searchValue}
                                  onChange={(e) => { setSearchValue(e.target.value); }}
                              />
                              <i className="fa fa-search"></i>
                          </div>
                      </div>
                      <div className="servicesItemWrap overflow-hidden col-md-12 col-12 system_faq">
                          {loading ? <Loader /> :
                              !!faqs?.length && faqs?.map((item) => (
                                  <FAQ
                                      key={item.id}
                                      content={item}
                                  />
                              ))
                          }
                      </div>
                  </div>
              </div>
              <div className="col-md-4 position-relative">
                  <div className="sticky-action">
                      <BusinessAction />
                      {/* <RevolutAffiliateBanner layout={'Faq'} /> */}
                  </div>
              </div>
          </div>
      </div>
        <EBook />
  </div>
  )
}

export default FAQs