import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import loader from '../../../dist/images/chz-loader.gif';
import Footer from '../Footer/Footer';
import MetaGenerator from '../widgets/MetaGenerator';
import VoucherItem from "./VoucherItem";

//API 
import { MY_VOUCHERS } from "../../../gql/Query";
import WhyChoose from '../widgets/whyChoose/WhyChoose';

export default function Vouchers() {
    const [voucherList, setVoucherList] = useState({});
    const [pages, setPages] = useState(18);
    const [paginate, setPaginate] = useState();
    const { data, loading } = useQuery(MY_VOUCHERS, {
        variables: {
            count: pages,
            page: 1
        }
    });
    const LoadMoreList = () => {
        setPages(pages + 18);
        // console.log("pages", pages)
        window.scrollTo(0, 1000);
    }
    useEffect(() => {
        if (data?.my_voucher?.data) {
            setVoucherList(data?.my_voucher?.data)
        }
        if (data?.my_voucher?.paginatorInfo) {
            setPaginate(data?.my_voucher?.paginatorInfo?.total)
        }
    }, [data])
    return (
        <>
            <MetaGenerator
                title="My vouchers"
            />
            <section id="vouchers" className="gray-bg">
                {loading &&
                    <div className='loader_wrap'>
                        <img src={loader} alt="chuzeday" width={180} />
                    </div>
                }
                {data &&
                    <div className="container pr-pt-150 pb-60">
                        <div className="row gy-4">
                            <h2 className="sectionHeading mb-0">My vouchers</h2>
                            {
                                voucherList?.length > 0 ?
                                    voucherList?.map((item) => {
                                        return (
                                            <VoucherItem key={item?.id} item={item} />
                                        )
                                    }) :
                                    <div className='resultItem text-center'>
                                        <h2>You haven't purchased any voucher yet!</h2>
                                    </div>
                            }
                        </div>
                    </div>
                }

                {
                    paginate > 18 &&
                    <p className="primaryBtn nextBtn" onClick={LoadMoreList}> See More</p>
                }
            </section>
            <WhyChoose />
            <Footer />
        </>
    )
}
