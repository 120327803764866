import React from 'react';
import { Link } from "react-router-dom";
import Logo from "../../../dist/images/logo.svg";
import Burger from './Burger';
import { Dropdown } from 'react-bootstrap';
import AuthHeader from "./AuthHeader";
import StickySearch from './StickySearch';

const SmHeader = () => {
    const guest_token = localStorage.getItem("guest_token");
    const prt_token = localStorage.getItem("token");
    return (
        <nav className="navbar mobile-navbar">
            <div className="container-fluid">
                <Burger />
                <Link to="/home" className="logo-wrap me-3">
                    <img src={Logo} alt="logo" />
                </Link>
                <StickySearch className="sticky-search d-xl-flex d-none" />
                <ul className="mobile-menu ms-auto d-flex align-items-center">
                    <li className="">
                        <Link to="/search-form">
                            <i className="far fa-search" />
                        </Link>
                    </li>
                    {
                        !guest_token ?
                            <li>
                                <Dropdown className="dropdown-wrap dropdown-wrap2">
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <i className="far fa-user"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="login-dropdown-body">
                                            <li><a className="dropdown-item" href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                            <li><Link to="/guest-login" className="dropdown-item border-0">Guests</Link></li>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li> :
                            <AuthHeader />
                    }
                </ul>
            </div>
        </nav>
    );
}
export default SmHeader;