import React from 'react'
import { Link } from "react-router-dom";
import bloglink from '../../../dist/images/blog2.png'

const LinkBlog = () => {
  return (
    <div className='container-fluid py-5 my-5'>
          <div className="position-relative ceo_img" data-aos="zoom-in">
            <Link to={`/system/blog`}>
                  <img
                      src={bloglink}
                      alt="blog"
                      className="img-fluid mx-auto d-block"
                  />
            </Link>
          </div>
    </div>
  )
}

export default LinkBlog
