import React from 'react'
import MetaGenerator from '../widgets/MetaGenerator'
import ScrollTop from '../widgets/ScrollTop'
import SeconderyHeader from '../Header/SeconderyHeader'
import WhyChoose from '../widgets/whyChoose/WhyChoose'
import Footer from '../Footer/Footer'
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import BusinessAction from '../widgets/CallToAction/BusinessAction';

const BonusMaterials = () => {
    const items = [
        {
            id: 1,
            title: "Delta: Represents the energy and empowerment of Chuzeday",
            description: "She can be used throughout the website, as her strength and determination as a business owner will relate with our Customer Business Owners. Again the cage has changed to her passion and brand.",
            slug: "delta-energy-empowerment",
            image: "/b3.jpg"
        },
        {
            id: 2,
            title: "Charlie: Represents his own confidence to grow his business.",
            description: "He can be used throughout the website as his fun and energetic vibe will relate to those like him and other Customer Business Owners. He wants to shout to the world about his amazing business and now he can with Chuzeday!",
            slug: "charlie-business-confidence",
            image: "/b2.jpg"
        },
        {
            id: 3,
            title: "Sierra: Represents how communication is key and how important it is to show up for ourselves and our clients in business.",
            description: "Like the others she can used throughout the website and her vibrancy and beauty will relate to those like her and other Customer Business Owners.",
            slug: "sierra-communication-key",
            image: "/b1.jpg"
        },
        {
            id: 4,
            title: "Romeo: Represents the cool, relaxed and technically gifted. ",
            description: "He wants you to know that its easy to be on Chuzeday. With our specialised On- boarding team, he gives us (Customer) the thumbs up to a better-balanced work/ life.Romeo can be used throughout the website and his smiling face and easy look with encourage young people to “Try out” a new way of working.",
            slug: "romeo-cool-relaxed",
            image: "/b4.jpg"
        }
    ];
  return (
      <>
          <MetaGenerator
              title="Bonus Materials"
          />
          <ScrollTop />
          <div id="filterHeader">
              <SeconderyHeader />
          </div>
          <div className='container g-profile-container pt-5 mt-5 font-chzday system mb-5'>
            <div className="row">
                <div className="col-md-8">
                    <div className="row blog-card">
                        <h1 className='sectionHeading text-center'>Bonus Materials</h1>
                        {items.map((item) => (
                            <div className="col-md-6 mb-4" key={item.id}>
                                <Link to={`/bonus/${item.slug}`}>
                                    <Card className='outer-card'>
                                        <Card.Img
                                            className="card img-fluid p-0"
                                            variant="top"
                                            src={`${item.image}`}
                                        />
                                        <Card.Body>
                                            <h2>{item.title}</h2>
                                            <Card.Text>
                                                <div className='bonus_desc'>{item.description}</div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-4 position-relative">
                    <div className="sticky-action">
                        <BusinessAction />
                    </div>
                </div>
            </div>
          </div>
          <WhyChoose />
          <Footer />
      </>
  )
}

export default BonusMaterials
