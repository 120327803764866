import React, { useState } from 'react';
import StickySearch from './StickySearch';
import MobileHeader from './MobileHeader';
import AuthHeader from "./AuthHeader";
import ScrollTop from "../widgets/ScrollTop";
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg';
import StickyHamburger from './StickyHamburger';

const SeconderyHeader = () => {
    const [navbar, setNavbar] = useState(false);
    const guest_token = localStorage.getItem("guest_token");
    const prt_token = localStorage.getItem("token");
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground);
    return (
        <>
            <ScrollTop />
            <header id="header" className="header-main">
                <div className="container-fluid navbarWrap">
                    <MobileHeader />
                    <Navbar expand="lg" className={navbar ? 'navbar active' : 'navbar d-xl-flex d-none'}>
                        <NavLink to="/home" className="logo me-3 d-xl-flex d-none">
                            <img src={Logo} alt="Logo-img" />
                        </NavLink>
                        <div className='d-xl-flex d-none'>
                            <StickySearch className="sticky-search" />
                        </div>
                        <MobileHeader />
                        <Nav
                            className="ms-auto my-2 my-lg-0 d-xl-flex d-none"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <ul className="primaryMenu sticky_navmenu d-xl-flex d-none">
                                <li><NavLink exact className="navItem" to="/system/faq" activeClassName="navItemActive">FAQ</NavLink></li>
                                <li><NavLink exact className="navItem" to="/system/blog" activeClassName="navItemActive">Blog</NavLink></li>
                                <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/how-it-works">
                                    How It Works
                                </NavLink></li>
                                <li>
                                    <NavLink exact className="navItem btn btn-outline-primary outline_round_btn ms-2" activeClassName="navItemActive" to="/system/book-a-demo">
                                        Book a call
                                    </NavLink>
                                </li>
                                {!guest_token &&
                                    <li className="dropdownWrap"><span className="navItem">Log In</span>
                                        <ul className="submenu">
                                            <li><a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                            <li><NavLink to="/guest-login">Guests</NavLink></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            {guest_token &&
                                <AuthHeader />
                            }
                        </Nav>
                    </Navbar>
                </div>
            </header>
        </>
    )
}

export default SeconderyHeader
