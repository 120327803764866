import React from 'react';
import SearchResult from './components/SearchResult/SearchResult';
// import RecommVenu from './components/recommVenus/RecommVenu';
import FixHeader from "../Header/FixHeader";
import Footer from "../Footer/Footer";
import WhyChoose from '../widgets/whyChoose/WhyChoose';

const Service = () => {
    return (
        <div>
            <FixHeader />
            <SearchResult />
            {/* <RecommVenu /> */}
            <WhyChoose />
            <Footer />
        </div>
    );
};

export default Service;