import React from 'react'
import why_csz from '../../../../dist/images/why_csg.png'
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

const WhyChoose = () => {
  return (
      <div className="container text-center why_choose" >
          <h2 className='mb-3'>Why Chuzeday for Your Small Business?</h2>
          <p>At Chuzeday, we empower Solo Business Owners with Smart, Seamless Software that streamlines operations, boosts profitability, and enhances customer service—helping YOU “WORK SMART
              GROW LOCAL
CONNECT GLOBAL”, and enjoy greater freedom.</p>
          <div className="image-container pb-4" data-aos="zoom-in-up">
              <img src={why_csz} alt="Why Chuzeday for Your Small Business?" className="mx-auto" />
          </div>
      <Link to={'/system/book-a-demo'} variant="warning" size="lg" className="fw-bold mt-4 rounded-pill round_btn why_csg_btn btn-warning">
              Book
              A Discovery Call <i class="far fa-angle-double-right"></i>
          </Link>
      </div>
  )
}

export default WhyChoose
