import React from 'react'
import MetaGenerator from '../widgets/MetaGenerator'
import ScrollTop from '../widgets/ScrollTop'
import SeconderyHeader from '../Header/SeconderyHeader'
import WhyChoose from '../widgets/whyChoose/WhyChoose'
import Footer from '../Footer/Footer'
import GetBonusMaterial from '../Home/components/GetBonusMaterials/GetBonusMaterial'

const GetBonusMaterials = () => {
  return (
      <>
          <MetaGenerator
              title="Get Bonus Materials"
          />
          <ScrollTop />
          <div id="filterHeader">
              <SeconderyHeader />
          </div>
          <div className='text-center'>
              <GetBonusMaterial />
          </div>
          <WhyChoose />
          <Footer />
      </>
  )
}

export default GetBonusMaterials
