import React from 'react';
import { Rating } from '@mui/material';
import { Link } from 'react-router-dom';

const TestimonialEbook = () => {
    const feature = {
        title: "Our Comprehensive E-book",
        description: "Gain actionable insights and strategies with our free e-book, designed to help you excel in your field. Discover expert tips and practical advice to achieve your goals.",
        testimonial: {
            text: "This e-book provided me with invaluable knowledge and clear guidance to enhance my professional skills.",
            author: "Michael Green",
            company: "Professional Consultant",
            image: "/michael.png",
            rating: 5
        },
        image: "/ebook.png"
    };

    return (
        <section className="features_section py-5">
            <div className="container mb-5">
                <div className="row align-items-center gx-5">
                    {/* Left Content */}
                    <div className="col-lg-6" data-aos="fade-right">
                        <div className="feature_text">
                            <h2 className="display-4 fw-bold mb-4">
                                {feature.title}
                            </h2>

                            <p className="text-secondary mb-4 short_desc">
                                {feature.description}
                            </p>

                            {/* Testimonial Card */}
                            <div className="d-flex gap-4 align-items-start p-4 review_card">
                                <div className="flex-shrink-0 reviewer">
                                    <img
                                        src={feature.testimonial.image}
                                        alt={feature.testimonial.author}
                                        className="rounded-circle"
                                        width="80"
                                        height="80"
                                    />
                                </div>

                                <div className="review_text">
                                    <p className="fst-italic text-secondary mb-2">
                                        "{feature.testimonial.text}"
                                    </p>

                                    <div className="d-flex gap-1 mb-2 rating_stars">
                                        <Rating
                                            value={feature.testimonial.rating}
                                            name="half-rating"
                                            precision={0.1}
                                            readOnly
                                        />
                                    </div>

                                    <div>
                                        <strong className="d-block">- {feature.testimonial.author}</strong>
                                        <span className="text-secondary">({feature.testimonial.company})</span>
                                    </div>
                                </div>
                            </div>

                            <Link to="/system/book-a-demo">
                                <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn mt-5">
                                    Book A Discovery Call
                                </button>
                            </Link>
                        </div>
                    </div>

                    {/* Right Content - Feature Image */}
                    <div className="col-lg-6" data-aos="fade-left">
                        <div className="position-relative">
                            <img
                                src={feature.image}
                                alt={feature.title}
                                className="img-fluid feature_img mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialEbook;
