import React, { useRef } from "react";
import Hero from './Hero'
import MetaGenerator from '../widgets/MetaGenerator'
import ScrollTop from '../widgets/ScrollTop'
import SeconderyHeader from '../Header/SeconderyHeader'
import WhyChoose from '../widgets/whyChoose/WhyChoose'
import Footer from '../Footer/Footer'
import EBook from '../Home/components/Ebook/EBook'
import Conversation from './Conversation'
import AdditionalFeature from './AdditionalFeature'
import HowSetup from './HowSetup'
import BlogLink from './BlogLink'
import Comparison from './Comparison'
import ComparisonGraph from './ComparisonGraph'
import LinkBlog from './LinkBlog'
import { Button } from "react-bootstrap";

const HowItWorks = () => {
  const eBookRef = useRef(null);

  const scrollToEBook = () => {
    eBookRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
        <MetaGenerator
            title="How It Works"
        />
        <ScrollTop />
        <div id="filterHeader">
            <SeconderyHeader />
        </div>
        <Hero />
        {/* <Conversation onScrollToEBook={scrollToEBook} /> */}
        <EBook ref={eBookRef} />
        <AdditionalFeature/>
        <BlogLink/>
        <Comparison/>
        <HowSetup/>
        <ComparisonGraph/>
        <LinkBlog/>
        <div onScrollToEBook={scrollToEBook} className="how_whychoose">
            <WhyChoose />
            <Button onClick={scrollToEBook} variant="warning" size="lg" className="fw-bold mt-4 rounded-pill round_btn ebook_link mx-auto d-block">
              Get
              Free eBook
            </Button>
        </div>
        <Footer />
    </>
  )
}

export default HowItWorks
