import React, { useEffect, useRef } from "react";

const GetBonusMaterial = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      (function() {
        try {
          var f = document.createElement("iframe");
          f.src = 'https://forms.zohopublic.eu/chuzeday1/form/BonusMaterial/formperma/yALx4l7yIWrToAjLCVY7k6BJLM2rDcWhQA3sYl-mTOo?zf_rszfm=1';
          f.style.border = "none";
          f.style.height = "352px";
          f.style.width = "100%";
          f.style.transition = "all 0.5s ease";
          f.setAttribute("aria-label", 'Bonus Material');

          var d = document.getElementById("zf_div_yALx4l7yIWrToAjLCVY7k6BJLM2rDcWhQA3sYl-mTOo");
          d.appendChild(f);

          window.addEventListener('message', function(event) {
            var evntData = event.data;
            if (evntData && evntData.constructor == String) {
              var zf_ifrm_data = evntData.split("|");
              if (zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3) {
                var zf_perma = zf_ifrm_data[0];
                var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
                var iframe = document.getElementById("zf_div_yALx4l7yIWrToAjLCVY7k6BJLM2rDcWhQA3sYl-mTOo").getElementsByTagName("iframe")[0];
                
                if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
                  var prevIframeHeight = iframe.style.height;
                  var zf_tout = false;
                  
                  if (zf_ifrm_data.length == 3) {
                    iframe.scrollIntoView();
                    zf_tout = true;
                  }
                  
                  if (prevIframeHeight != zf_ifrm_ht_nw) {
                    if (zf_tout) {
                      setTimeout(function() {
                        iframe.style.height = zf_ifrm_ht_nw;
                      }, 500);
                    } else {
                      iframe.style.height = zf_ifrm_ht_nw;
                    }
                  }
                }
              }
            }
          }, false);
        } catch(e) {}
      })();
    `;

    const div = document.createElement("div");
    div.id = "zf_div_yALx4l7yIWrToAjLCVY7k6BJLM2rDcWhQA3sYl-mTOo";

    if (containerRef.current) {
      containerRef.current.appendChild(div);
      containerRef.current.appendChild(script);
    }

    // Cleanup function
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, []);

  return <div ref={containerRef} className="w-100 max-w-3xl mx-auto" />;
};

export default GetBonusMaterial;
