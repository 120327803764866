import React from 'react'
import faqimg from '../../../../dist/images/faq.jpg'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <div className='container-fluid px-0 text-center faq_banner pb-5 mb-5 position-relative' data-aos="zoom-in" >
        <img
          src={faqimg}
          alt="Faq"
          className="img-fluid mx-auto"
        />
      <Link to={'/how-it-works'} className="d-block fw-bold rounded-pill round_btn position-absolute bottom-2 right-2">
        Learn More <i class="far fa-angle-double-right"></i>
      </Link>
    </div>
  )
}

export default Banner
