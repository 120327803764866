import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const features = [
    { icon: <i className="far fa-globe"></i>, label: "Webpage" },
    { icon: <i className="far fa-chart-line"></i>, label: "Dashboard" },
    { icon: <i class="far fa-hand-pointer"></i>, label: "Bookings" },
    { icon: <i className="far fa-calendar"></i>, label: "Calendar" },
    { icon: <i class="far fa-file-alt"></i>, label: "Invoice" },
    { icon: <i class="far fa-list-alt"></i>, label: "Inventory" },
    { icon: <i class="far fa-hand-holding"></i>, label: "CRM" },
    { icon: <i class="far fa-money-check"></i>, label: "Payments" },
    { icon: <i class="far fa-gift"></i>, label: "Vouchers" },
];

const BusinessFeatures = () => {
    return (
        <Container className="text-center my-5">
            <h2 className="text-center sec_heading mb-5">Features of Chuzedays Business App</h2>
            <Row className="justify-content-center">
                {features.map((feature, index) => (
                    <Col key={index} xs={4} sm={3} md={1} className="mb-4">
                        <div className="feature-box">
                            <div className="icon" data-aos="zoom-in">{feature.icon}</div>
                            <p className="mt-2">{feature.label}</p>
                        </div>
                    </Col>
                ))}
            </Row>
            <Link to={'/system/book-a-demo'} variant="warning" size="lg" className="fw-bold mt-3 rounded-pill round_btn btn-warning">
                Book
A Discovery Call <i class="far fa-angle-double-right"></i>
            </Link>
        </Container>
    );
};

export default BusinessFeatures;
