import React from 'react'

const BookingEmbed = () => {
  return (
    <>
        <iframe width='100%' height='480px' src='https://chuzeday.zohobookings.eu/portal-embed#/chuzeday' frameborder='0' allowfullscreen='' ></iframe >
    </>
  )
}

export default BookingEmbed
