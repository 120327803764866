import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import '../../../dist/css/style.css';
import '../../../dist/css/responsive.css';
import Avatar from '../../../dist/images/guestlogin/blank.png';
import { useQuery, useMutation, useApolloClient, useLazyQuery } from '@apollo/client';
import pusher from "../../pusher/pusher";

//API
import { PROFILE_INFO, NOTIFICATION_SEEN, MY_NOTIFICATION } from "../../../gql/Query";
import { AUTH_LOGOUT } from "../../../gql/Mutation";

const AuthHeader = () => {
    const [count, setCount] = useState(0)
    const [profile, setProfile] = useState([]);
    const { data, error } = useQuery(PROFILE_INFO);
    const [fetchNotification, { error: NError }] = useLazyQuery(NOTIFICATION_SEEN, {
        fetchPolicy: "network-only"
    });
    const { data: notification, error: notificationerror, refetch } = useQuery(MY_NOTIFICATION)
    const history = useHistory();
    //Logout
    const client = useApolloClient();
    const [logout] = useMutation(AUTH_LOGOUT);
    const logOut = () => {
        const token = localStorage.getItem("guest_token")
        if (token) {
            setTimeout(() => {
                localStorage.clear();
                client.cache.reset();
            }, 100)
            logout()
                .then(({ data }) => {
                    if (data) {
                        console.log(data);
                        //history.push("/")
                    }
                })
                .catch(err => console.log(err))
        } else {
            history.push("/")
        }
    }
    const checkNotification = () => {
        fetchNotification();
        refetch();
        setTimeout(() => {
            history.push("/profile/notification");
        }, 300);
    }
    useEffect(() => {
        //------------pusher configuration-----------------
        //console.log("profile?.client_detail?.id", profile?.client_detail?.id)
        if (profile?.client_detail?.id) {
            const channel = pusher?.subscribe(`notification-channel.${profile?.client_detail?.id}`);
            channel.bind('notification', function (data) {
                refetch();
            });
        }

        if (data) {
            setProfile(data.profileInformation);
            // console.log(data.profileInformation)
        }
        if (notification) {
            setCount(notification?.myNotification?.count?.new_notification)
        }
        if (NError) {
            console.log(NError)
        }
        if (error) {
            console.log(error);
        }
        if (notificationerror) {
            console.log(notificationerror)
        }
    }, [data, profile?.client_detail?.id, NError, error, notification, notificationerror])

    return (
        <div className='d-flex'>
            <div className='me-md-2'>
                <p
                    onClick={checkNotification}
                    className='h1 btn btn-lg mt-2 position-relative notification'
                >
                    <i className="far fa-bell" />
                    <span style={{ color: "#FD8E7C", borderRadius: "2px" }} className='position-absolute h6 p-2 bg-white fw-bold'>{count < 99 ? count : "99+"}</span>
                </p>
            </div>
            <div className="guestProfile text-center">
                <Dropdown className="" align="end">
                    <Dropdown.Toggle id="dropdown-autoclose-true" className="avatar-toggle-btn">
                        <img src={profile.photo ? `https://chuzeday.com/uploads/guest/${profile?.photo}` : Avatar} className="avatar" alt="guest" />
                        <i className="fas fa-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Link to="/" className="g-name pb-2 top dropdown-item">{`${profile.first_name ? profile.first_name : ""} ${profile.last_name ? profile.last_name : ""}`}</Link>
                        <Link to="/profile/my-profile" className="dropdown-item py-2 top g-profile">My Profile</Link>
                        <Dropdown.Divider />
                        <Link className='dropdown-item' to="/profile/appointments"><i className="fa fa-calendar"></i> My Appointments</Link>
                        <Link className='dropdown-item' to="/profile/vouchers"><i className="fa fa-money-bill"></i>My Vouchers</Link>
                        <Link className='dropdown-item' to="/profile/favorites"><i className="fa fa-heart"></i>My favorites</Link>
                        {
                            !profile.social_login &&
                            <Link className='dropdown-item' to="/profile/change-password"><i className="fa fa-key"></i>Change Password</Link>
                        }
                        <Dropdown.Item onClick={logOut}><span><i className="fas fa-sign-out-alt"></i>Logout</span></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <p className="guest-name">{`${profile.first_name ? profile.first_name : ""}`}</p> */}
            </div>
        </div>
    )
}

export default AuthHeader
