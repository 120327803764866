import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import FixHeader from '../../../Header/FixHeader';
import Footer from '../../../Footer/Footer';
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import MetaGenerator from '../../../widgets/MetaGenerator';


//API
import { SINGLE_PARTNER_FEATURE } from "../../../../../gql/Query";
import WhyChoose from '../../../widgets/whyChoose/WhyChoose';

const SingleFeature = () => {
    const imageBaseURL = "https://chuzeday.com/";
    const [features, setFeatures] = useState();
    const { slug } = useParams();
    const { data, error, loading } = useQuery(SINGLE_PARTNER_FEATURE, {
        variables: {
            slug: slug
        }
    });
    useEffect(() => {
        if (data) {
            setFeatures(data?.partner_feature);
        }
        if (error) {
            console.log(error);
        }
    }, [data, error]);

    const settings = {
        autoplay: true,
        infinite: true,
        speed: 500,
        // autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <FixHeader />
            <div className="container-fluid single-partner-feature">
                {
                    loading ?
                        <div className='d-flex justify-content-center'>
                            <Spinner animation="border" variant="primary" />
                        </div> :
                        (features &&
                            <>
                                <MetaGenerator title={features?.title} />
                                <section id="Feature">
                                    <div className="FeatureSlider">
                                        <Slider {...settings}>
                                            {
                                                features?.sliders &&
                                                JSON.parse(features?.sliders)?.map((item, index) => (
                                                    <div className="banerimgWrap" key={index}>
                                                        {
                                                            item?.youtube_url ?
                                                                <iframe
                                                                    src={`https://www.youtube.com/embed/${item?.youtube_url}?rel=0`}
                                                                    title="YouTube video player"
                                                                    frameborder="0"
                                                                    width={"100%"}
                                                                    height={235}
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                    allowfullscreen
                                                                />
                                                                :
                                                                <>
                                                                    <img src={`${imageBaseURL}/${item?.image}`} alt="visit website" />
                                                                    <div className="caption">
                                                                        <h1>
                                                                            {item?.title}
                                                                        </h1>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </section>
                                <div className="container mt-5">
                                    <div className='font-chzday' dangerouslySetInnerHTML={{ __html: features?.description }} />
                                </div>
                            </>
                        )
                }
            </div>
            <WhyChoose />
            <Footer />
        </>
    )
}

export default SingleFeature