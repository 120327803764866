import GooglePayButton from "@google-pay/button-react";
import React, { useContext } from "react";
import "dotenv/config";
import { cartList } from "./ShopService";

const GooglePayment = ({ amount, setGPaymentData: propSetGPaymentData }) => {
  // Attempt to get the context, if available
  let contextSetGPaymentData;
  try {
    const context = useContext(cartList);
    if (context) {
      contextSetGPaymentData = context.setGPaymentData;
    }
  } catch (error) {
    console.warn("GooglePayment: cartList provider is not available.");
  }

  // Use context `setGPaymentData` if available, otherwise fallback to the prop
  const setGPaymentData = contextSetGPaymentData || propSetGPaymentData;

  const handleLoadPaymentData = (paymentRequest) => {
    if (setGPaymentData) {
      setGPaymentData({
        ...paymentRequest,
        id: JSON.parse(paymentRequest.paymentMethodData.tokenizationData.token)
            ?.id ?? "",
      });
    } else {
      console.warn(
        "GooglePayment: Payment data was loaded, but no setGPaymentData function is available."
      );
    }
    console.log("load payment data", paymentRequest);
  };

  return (
    <GooglePayButton
      // environment="PRODUCTION"
      environment="TEST"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: [
                "AMEX",
                "DISCOVER",
                "JCB",
                "MASTERCARD",
                "VISA",
              ],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "stripe",
                "stripe:version": "2024-10-28.acacia",
                "stripe:publishableKey":
                  process.env.REACT_APP_STRIPE_PAY_MERCHANT_ID,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
          merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME,
        },
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPriceLabel: "Total",
          totalPrice: amount?.toString(),
          currencyCode: "EUR",
          countryCode: "IE",
        },
        shippingAddressParameters: {
          phoneNumberRequired: false,
          allowedCountryCodes: ["US", "GB", "IE"],
        },
      }}
      buttonColor="dark"
      buttonSizeMode="fill"
      buttonRadius={10}
      className="google-pay-btn"
      buttonType="book"
      onLoadPaymentData={handleLoadPaymentData}
      onError={(err) => {
        console.error("Google Pay Error", err);
      }}
    />
  );
};

export default GooglePayment;
