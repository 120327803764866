import React from 'react';

const Comparison = () => {
    const tableData = {
        headers: [
            { title: 'Without Software', subtitle: 'Diary, Phone, Email, Messages etc' },
            { title: 'Other Software', subtitle: 'Booking Widgets, Full CRMs etc' },
            { title: 'CHUZEDAY', subtitle: '' }
        ],
        rows: [
            [
                'Completely distracting and highly time-consuming process of Appointment Management',
                'Lots of hidden charges, they never cost what was anticipated at the start.',
                'Quick & Easy Setup: We assist you in entering your business details, services,and key essentials just once—then you are ready to go!'
            ],
            [
                'Lots of uncertainty for both you and your clients.',
                'Loosing clients to other businesses on the same software as they shift your clients to them for commission.',
                'We launch a campaign to get your existing customers booking through Chuzeday—so you don’t have to worry about spreading the word!'
            ],
            [
                'Missed Revenue, "No Shows" & "Late Cancellations"',
                'You get generic support messages because you\'re just one of many of users.',
                'Dedicated support from experts who truly understand your business and are invested in your success.'
            ],
            [
                'Posting on Social that you have "Last Minute Availability"',
                'All those complicated features take too much time to figure out and often feel like overkill. (You just want simple!)',
                'User-friendly software and business app—update your services, details, promotions, and more in minutes.'
            ],
            [
                'No way to easily attract new clients.',
                'You clients refuse to using the software because it\'s too clunky.',
                'Effortless automation for appointments—reminders, updates, rescheduling, and more handled for you.'
            ],
            [
                'Difficult to control your inventory.',
                'You fork out a lot of money each year and still are not sure if you\'re making money or not',
                'Seamless payment integration with Google Pay, Apple Pay, and Stripe.'
            ],
            [
                'Missing the opportunity to up products along with your services',
                '',
                'Begin with Booking & Payments, and a full-featured CRM. then upgrade as you grow with accounting, inventory management, analytics, group bookings and more.'
            ],
            [
                'It\'s never really clear how your business is doing until it\'s time to do your year-end taxes. (By then it\'s too late to do anything about it.)',
                '',
                'Powerful business tools, designed for solo entrepreneurs and easy to use.'
            ]
        ]
    };
    const renderCell = (text, cellIndex) => {
        if (!text) return null;

        return (
            <div className="d-flex align-items-start gap-2">
                {cellIndex === 2 ? (
                    <i className="far fa-check text-warning mt-2"></i>
                ) : (
                    <i className="far fa-times text-danger mt-2"></i>
                )}
                <p className={`small mb-0 ${cellIndex === 2 ? '' : 'text-secondary'}`}>{text}</p>
            </div>
        );
    };
    return (
        <div className="container py-5 my-5 comparison">
            <h2 className='sec_heading text-center mb-5'>
                Why Chuzeday V’s
                Other Solutions</h2>
            <div className="table-responsive" data-aos="flip-left">
                <table className="table table-bordered">
                    <thead>
                        <tr className="heading text-white">
                            {tableData.headers.map((header, index) => (
                                <th key={index} className="text-center p-3" style={{ width: '33.33%' }}>
                                    <h5 className="mb-1">{header.title}</h5>
                                    {header.subtitle && <small className="d-block">{header.subtitle}</small>}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='border-0'>
                        {tableData.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        className={`p-3 ${cellIndex === 2 ? 'bg-primary bg-opacity-10' : 'bg-light'}`}
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        {renderCell(cell, cellIndex)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* <div className="row g-4">
                <div className="col-md-4">
                    <div className="bg-light p-4 rounded h-100">
                        <h3 className="fw-semibold mb-4">Using spreadsheets & word docs</h3>
                        <div className="d-flex flex-column gap-3">
                            {[
                                "Completely distracting and highly time-consuming process of Appointment Management",
                                "Lots of uncertainty for both you and your clients.",
                                "Missed Revenue, 'No Shows' & 'Late Cancellations'",
                                "Posting on Social that you have 'Last Minute Availability'",
                                "No way to easily attract new clients.",
                                "Difficult to control your inventory.",
                                "Missing the opportunity to up products along with your services",
                                "It's never really clear how your business is doing until it's time to do your year-end taxes. (By then it's too late to do anything about it.)"
                            ].map((text, index) => (
                                <div key={index} className="d-flex align-items-start gap-2">
                                    <i className="far fa-times text-danger mt-2"></i>
                                    <p className="text-secondary small mb-0">{text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className="col-md-4">
                    <div className="bg-light p-4 rounded h-100">
                        <h3 className="fw-semibold mb-4">Using other software</h3>
                        <div className="d-flex flex-column gap-3">
                            {[
                                "Lots of hidden charges, they never cost what was anticipated at the start.",
                                "Loosing clients to other businesses on the same software as they shift your clients to them for commission.",
                                "You get generic support messages because you're just one of many of users.",
                                "All those complicated features take too much time to figure out and often feel like overkill. (You just want simple!)",
                                "You clients refuse to using the software because it's too clunky.",
                                "You fork out thousands of dollars a year and still are not sure if you're making money or not"
                            ].map((text, index) => (
                                <div key={index} className="d-flex align-items-start gap-2">
                                    <i className="far fa-times text-danger mt-2"></i>
                                    <p className="text-secondary small mb-0">{text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="right_chuzeday text-white p-4 rounded-4 h-100">
                        <h3 className="fw-semibold mb-4 text-center">With Chuzeday you get everything you need</h3>
                        <div className="d-flex flex-column gap-3">
                            {[
                                "Fast Set-Up; We help you input your business details, services and some other simple business items once... and it's done.",
                                "We run a campaign to get your existing customers booking you on Chuzeday, you don't have to worry about letting them know you've started on Chuzeday.",
                                "Hands-on support from people who're passionate about your business success and just 'get it.'",
                                "Easy to use software and Business App, update your services, business details, promotional images and more in minutes.",
                                "Automation that takes the effort out of running appointments (Reminder, Updates, Rescheduling, etc).",
                                "Powerful Payment Integration with Google Pay, Apple Pay and Stripe.",
                                "Start with Booking & Payments; then as you Grow upgrade to a Fully loaded CRM with Accountancy Package, Inventory Management, Analytics and much more",
                                "Big Business Tools for Your Small Business that are easy to use"
                            ].map((text, index) => (
                                <div key={index} className="d-flex align-items-start gap-2">
                                    <i className="far fa-check text-warning mt-2"></i>
                                    <p className="small mb-0">{text}</p>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-warning w-100 rounded-pill text-white fw-semibold">
                                BOOK A DEMO
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Comparison;