import React, { useEffect, useState } from 'react';
import { PAGE_VIDEOS } from '../../../gql/Query';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

const Hero = () => {
    const [video, setVideo] = useState({})
    console.log("🚀 ~ DownloadApp ~ video:", video)
    const { data: page_videos, error: page_videos_error } = useQuery(PAGE_VIDEOS, {
        variables: {
            page: "home"
        }
    })
    useEffect(() => {
        if (page_videos) {
            console.log("🚀 ~ DownloadApp ~ page_videos:", page_videos)
            setVideo(page_videos.page_videos)
        }
    }, [page_videos])
    return (
        <div className="container py-5 my-5 how_works">
            {/* <h2 class="text-center sec_heading mb-3">Your eBook is on its way to you</h2>
            <p class="sec_desc mb-5">Check your email/text message</p> */}
            <div className="row gx-4 align-items-center">
                <div className="col-md-6">
                    <div className="video-container">
                        <iframe
                            id="youtube-player"
                            className="w-100"
                            style={{ aspectRatio: '16/9' }}
                            src={`https://www.youtube.com/embed/JvLPHwNaSoU?autoplay=0&mute=0&loop=1&playlist=JvLPHwNaSoU&amp;&controls=1`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                <div className="col-md-6">  
                    <div className="p-4 text-center">
                        <h2 className="sec_heading mb-3">
                            Your Free eBook is on its way to you
                        </h2>
                        <p className=''>
                            Check your email/text messages for your copy!
                        </p>
                        <p className=''>
                            Also check out the <strong><Link to='/get-bonus-materials' className='text-dark'>Bonus Materials</Link></strong> links
                        </p>
                        <p>You’ll find these inside the eBook</p>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <h6 className="display-6 my-3 text-center">Book a free Discovery Call with the team; find out how   Chuzeday can solve your business issues</h6>
                <Link to="/system/book-a-demo">
                    <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn mx-auto d-block">
                        Book A Discovery Call
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Hero;