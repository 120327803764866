import React from 'react'
import Hero from './Hero'
import MetaGenerator from '../widgets/MetaGenerator'
import SeconderyHeader from '../Header/SeconderyHeader'
import WhyChoose from '../widgets/whyChoose/WhyChoose'
import Footer from '../Footer/Footer'
import ScrollTop from '../widgets/ScrollTop'
import TestimonialEbook from './TestimonialEbook'
import BonusLink from './BonusLink'
import HowSetup from '../HowItWorks/HowSetup'

const ThankYou = () => {
  return (
    <>
        <MetaGenerator
            title="Thank you for downloading eBook"
        />
        <ScrollTop />
        <div id="filterHeader">
            <SeconderyHeader />
        </div>
        <Hero/>
        <TestimonialEbook/>
        <BonusLink/>
        <HowSetup/>
        <WhyChoose />
        <Footer />
    </>
  )
}

export default ThankYou
