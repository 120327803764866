import React from 'react';
import { useParams } from 'react-router-dom';
import MetaGenerator from '../widgets/MetaGenerator';
import ScrollTop from '../widgets/ScrollTop';
import SeconderyHeader from '../Header/SeconderyHeader';
import Footer from '../Footer/Footer';
import WhyChoose from '../widgets/whyChoose/WhyChoose';
import { Link } from 'react-router-dom';

const bonusItems = [
    {
        slug: "delta-energy-empowerment",
        title: "Delta: Represents the energy and empowerment of Chuzeday",
        description: "She can be used throughout the website, as her strength and determination as a business owner will relate with our Customer Business Owners. Again the cage has changed to her passion and brand.",
        vcode: "wRP0VtAegu4"
    },
    {
        slug: "charlie-business-confidence",
        title: "Charlie: Represents his own confidence to grow his business.",
        description: "He can be used throughout the website as his fun and energetic vibe will relate to those like him and other Customer Business Owners. He wants to shout to the world about his amazing business and now he can with Chuzeday!",
        vcode: "NI-m-uwKMPs"
    },
    {
        slug: "sierra-communication-key",
        title: "Sierra: Represents how communication is key...",
        description: "Like the others she can used throughout the website and her vibrancy and beauty will relate to those like her and other Customer Business Owners.",
        vcode: "bZLVl2f5JWI"
    },
    {
        slug: "romeo-cool-relaxed",
        title: "Romeo: Represents the cool, relaxed, and technically gifted.",
        description: "He wants you to know that its easy to be on Chuzeday. With our specialised On- boarding team, he gives us (Customer) the thumbs up to a better-balanced work/ life.Romeo can be used throughout the website and his smiling face and easy look with encourage young people to “Try out” a new way of working.",
        vcode: "l64lKFfwsQo"
    }
];

const SingleBonus = () => {
    const { slug } = useParams(); // Get dynamic slug from URL
    const item = bonusItems.find((bonus) => bonus.slug === slug);

    if (!item) {
        return <h2 className="text-center">Bonus Item Not Found</h2>;
    }

    return (
        <>
            <MetaGenerator
                title={item.title}
            />
            <ScrollTop />
            <div id="filterHeader">
                <SeconderyHeader />
            </div>
            <div className="container py-5 my-5 how_works">
                <div className="row align-items-center gx-4">
                    <div className="col-md-7">
                        <div className="video-container">
                            <iframe
                                id="youtube-player"
                                className="w-100"
                                style={{ aspectRatio: '16/9' }}
                                src={`https://www.youtube.com/embed/iUseITmRT-I?autoplay=0&mute=0&loop=1&playlist=iUseITmRT-I&amp;&controls=1`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <h3 className="display-6 mt-3 text-center">Deborah Jordan CEO</h3>
                    </div>
                    <div className="col-md-5">
                        <div className="p-4">
                            <h2 className="display-5 mb-5 text-center">
                            Welcome, lets
                            watch a video from
                            Deborah and learn
                            how Chuzeday
                            automates your
                            Individual business
                            </h2>
                            <Link to={'/system/book-a-demo'} className="btn btn-dark btn-lg rounded-pill round_btn mx-auto d-block btn-warning">
                                Book A
                                Discovery Call
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5 my-5 how_works">
                <div className="row align-items-center gx-4">
                    <div className="col-md-5">
                        <div className="p-4">
                            <h2 className="display-5 mb-5 text-center">
                                {item.title}
                            </h2>
                            <p className="text-center mb-4">{item.description}</p>
                            <Link to={'/system/book-a-demo'} className="btn btn-dark btn-lg rounded-pill round_btn mx-auto d-block btn-warning">
                                Book A
                                Discovery Call
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="video-container">
                            <iframe
                                id="youtube-player"
                                className="w-100"
                                style={{ aspectRatio: '16/9' }}
                                src={`https://www.youtube.com/embed/${item.vcode}?autoplay=0&mute=0&loop=1&playlist=${item.vcode}&amp;&controls=1`} 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        {/* <h3 className="display-6 mt-3 text-center">Deborah Jordan CEO</h3> */}
                    </div>
                </div>
            </div>
            <WhyChoose />
            <Footer />
        </>
    );
};

export default SingleBonus;
