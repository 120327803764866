import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg'
import { Navbar, Nav } from 'react-bootstrap';
import StickySearch from './StickySearch';
import AuthHeader from "./AuthHeader";
import ScrollTop from "../widgets/ScrollTop";
import SmHeader from './SmHeader';

export default function FixHeader() {
    const [navbar, setNavbar] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const { shop_slug } = useParams();
    const [guest_token, setGuest_token] = useState();
    const prt_token = localStorage.getItem("token");
    useEffect(() => {
        let intervalId;

        if (shop_slug) {
            intervalId = setInterval(() => {
                setGuest_token(localStorage.getItem("guest_token"));
            }, 1000);
        } else {
            setGuest_token(localStorage.getItem("guest_token"));
        }

        // Clean-up function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [shop_slug]);

        //Search services
        const changeBackground = () => {
            if (window.scrollY >= 80) {
                // Check if the component is still mounted before updating state
                if (isMounted) {
                    setNavbar(true);
                }
            } else {
                // Check if the component is still mounted before updating state
                if (isMounted) {
                    setNavbar(false);
                }
            }
        };
    
        useEffect(() => {
            // Component is mounted
            setIsMounted(true);
    
            // Bind the event listener
            window.addEventListener('scroll', changeBackground);
    
            // Clean up function to be executed when the component is unmounted
            return () => {
                // Component is unmounted
                setIsMounted(false);
    
                // Unbind the event listener on cleanup
                window.removeEventListener('scroll', changeBackground);
            };
        }, [setNavbar]);

    return (
        <>
            <ScrollTop />
            <div id="fix-header">
                <header className="d-block d-lg-none mobile-header" id="header">
                    <SmHeader />
                </header>
                <Navbar expand="lg" className={navbar ? 'navbar active' : 'navbar d-xl-flex d-none'}>
                    <NavLink to="/home" className="logo d-xl-flex d-none">
                        <img src={Logo} alt="Logo-img" />
                    </NavLink>
                    <header className="d-block d-xl-none mobile-header w-100 sm_sticky_hdr" id="header">
                        <SmHeader />
                    </header>
                    <Nav
                        className="ms-auto my-2 my-lg-0 d-xl-flex d-none"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <StickySearch className="sticky-search d-xl-flex d-none" />
                        <ul className="primaryMenu sticky_navmenu ms-2 d-xl-flex d-none">
                            <li><NavLink exact className="navItem" to="/system/blog">Blog</NavLink></li>
                            <li><NavLink exact className="navItem" to="/how-it-works">How It Works</NavLink></li>
                            <li><NavLink exact className="navItem btn btn-outline-primary outline_round_btn" to="/system/book-a-demo">Book a demo</NavLink></li>
                            {
                                !guest_token &&
                                <li className="dropdownWrap"><NavLink className="navItem" to="/">Log In</NavLink>
                                    <ul className="submenu">
                                        <li><a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                        <li><NavLink to="/guest-login">Guests</NavLink></li>
                                    </ul>
                                </li>
                            }
                        </ul>
                        {guest_token &&
                            <AuthHeader />
                        }
                    </Nav>
                </Navbar>
            </div>
        </>
    )
}
