import React, { useEffect, useRef } from "react";

const EBookFormZoho = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    // Only run this once when the component mounts
    if (formContainerRef.current) {
      // Create a div with the specific ID required by Zoho
      const zohoDiv = document.createElement("div");
      zohoDiv.id = "zf_div_N-m2fitKZ94zhzP3t_lXpf1iijb1zR8yLt-KN8vNW6A";
      formContainerRef.current.appendChild(zohoDiv);

      // Create and append the script element
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = `(function() {
                try{
                    var f = document.createElement("iframe");
                    f.src = 'https://forms.zohopublic.eu/chuzeday1/form/EBookForm/formperma/N-m2fitKZ94zhzP3t_lXpf1iijb1zR8yLt-KN8vNW6A?zf_rszfm=1';
                    f.style.border="none";
                    f.style.height="615px";
                    f.style.width="90%";
                    f.style.transition="all 0.5s ease";
                    f.setAttribute("aria-label", 'E\\x2DBook\\x20Form');
                    var d = document.getElementById("zf_div_N-m2fitKZ94zhzP3t_lXpf1iijb1zR8yLt-KN8vNW6A");
                    d.appendChild(f);
                    window.addEventListener('message', function (){
                        var evntData = event.data;
                        if( evntData && evntData.constructor == String ){
                            var zf_ifrm_data = evntData.split("|");
                            if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
                                var zf_perma = zf_ifrm_data[0];
                                var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
                                var iframe = document.getElementById("zf_div_N-m2fitKZ94zhzP3t_lXpf1iijb1zR8yLt-KN8vNW6A").getElementsByTagName("iframe")[0];
                                if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
                                    var prevIframeHeight = iframe.style.height;
                                    var zf_tout = false;
                                    if( zf_ifrm_data.length == 3 ) {
                                        iframe.scrollIntoView();
                                        zf_tout = true;
                                    }
                                    if ( prevIframeHeight != zf_ifrm_ht_nw ) {
                                        if( zf_tout ) {
                                            setTimeout(function(){
                                                iframe.style.height = zf_ifrm_ht_nw;
                                            },500);
                                        } else {
                                            iframe.style.height = zf_ifrm_ht_nw;
                                        }
                                    }
                                }
                            }
                        }
                    }, false);
                }catch(e){}
            })();`;
      formContainerRef.current.appendChild(script);
    }

    // Cleanup function to remove the script when component unmounts
    return () => {
      if (formContainerRef.current) {
        const script = formContainerRef.current.querySelector("script");
        if (script) {
          script.remove();
        }
        const zohoDiv = document.getElementById(
          "zf_div_N-m2fitKZ94zhzP3t_lXpf1iijb1zR8yLt-KN8vNW6A"
        );
        if (zohoDiv) {
          zohoDiv.remove();
        }
      }
    };
  }, []);

  return (
    <div className="">
      <div ref={formContainerRef} className="row justify-content-center">
        {/* Zoho Form will be injected here */}
      </div>
    </div>
  );
};

export default EBookFormZoho;
