import React, { useEffect, Fragment } from 'react';

const DownloadBookPdf = () => {
  useEffect(() => {
    // Dynamically inject the Zoho script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function () {
        try {
          var f = document.createElement("iframe");
          f.src = 'https://forms.zohopublic.eu/chuzeday1/form/EBook/formperma/F_qieahYyka9Wjv3pKKcRhCE9ECMfDgGPpreXoRTEkY?zf_rszfm=1';
          f.style.border = "none";
          f.style.height = "704px";
          f.style.width = "100%";
          f.style.transition = "all 0.5s ease";
          f.setAttribute("aria-label", 'Free\x20E\x2DBook');

          var d = document.getElementById("zf_div_F_qieahYyka9Wjv3pKKcRhCE9ECMfDgGPpreXoRTEkY");
          d.appendChild(f);

          window.addEventListener('message', function (event) {
            var evntData = event.data;
            if (evntData && evntData.constructor == String) {
              var zf_ifrm_data = evntData.split("|");
              if (zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3) {
                var zf_perma = zf_ifrm_data[0];
                var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
                var iframe = document.getElementById("zf_div_F_qieahYyka9Wjv3pKKcRhCE9ECMfDgGPpreXoRTEkY").getElementsByTagName("iframe")[0];
                if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
                  var prevIframeHeight = iframe.style.height;
                  var zf_tout = false;
                  if (zf_ifrm_data.length == 3) {
                    iframe.scrollIntoView();
                    zf_tout = true;
                  }
                  if (prevIframeHeight != zf_ifrm_ht_nw) {
                    if (zf_tout) {
                      setTimeout(function () {
                        iframe.style.height = zf_ifrm_ht_nw;
                      }, 500);
                    } else {
                      iframe.style.height = zf_ifrm_ht_nw;
                    }
                  }
                }
              }
            }
          }, false);
        } catch (e) { }
      })();
    `;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Fragment>
      <div id="zf_div_F_qieahYyka9Wjv3pKKcRhCE9ECMfDgGPpreXoRTEkY"></div>
    </Fragment>
  );
};

export default DownloadBookPdf;
