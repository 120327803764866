import React, { createContext, useState, useEffect, useRef } from 'react';
import FixHeader from '../Header/FixHeader';
import ShopBaner from '../SingleShop/components/ShopBaner';
import ShopAbout from '../SingleShop/components/ShopAbout';
// import Offer from '../SingleShop/components/Offers/Offer';
import ShopService from '../SingleShop/components/ShopServices/ShopService';
import Review from '../SingleShop/components/Reviews/Reviews';
import ShopBottom from '../SingleShop/components/ShopBottom';
import loader from './../../../dist/images/chz-loader.gif';
import Footer from '../Footer/Footer';
import { useQuery } from '@apollo/client';
import { useParams, Link } from "react-router-dom";
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { SINGLE_SHOP, SERVICE_LIST } from "../../../gql/Query";
import { toast } from 'react-toastify';
import WhyChoose from '../widgets/whyChoose/WhyChoose';

export const ContSerVcr = createContext();

const SingleShop = () => {

    //------------------Single shop query--------------------------
    const { shop_slug } = useParams();
    const { data, loading: shopLoading,  refetch: refetchShop } = useQuery(SINGLE_SHOP, {
        variables: {
            slug: shop_slug
        },
        fetchPolicy: "network-only"
    });

    const [singleShop, setSingleShop] = useState({})
    const [business, setBusiness] = useState({})
    const [shopSlotDuration, setShopSlotDuration] = useState(0);
    useEffect(() => {
        if (data) {
            setSingleShop(data);
            setBusiness(data.single_business?.business);
            setShopSlotDuration(data.single_business?.business?.slot_duration)
            // console.log("single shop",data.single_business)
            // console.log("data.single_business", data.single_business);
            window.scrollTo(0, 0);
        }
    }, [data])
    //Single shops services query
    const [serviceList, setServiceList] = useState([])
    const [groupServices, setGroupServices] = useState([])

    const { data: service_cat, loading: serviceLoading, error: serviceError } = useQuery(SERVICE_LIST, {
        variables: {
            type: "business",
            business_id: parseInt(business?.id)
        }
    });

    useEffect(() => {
        if (serviceError) {
            toast.error(serviceError);
        }
        if (service_cat) {
            const genericServiceList = service_cat.service_categories && 
                service_cat.service_categories.map((service) => ({
                    ...service,
                    services: service.services.filter((item) => item.is_group === false)
                }))
                .filter((service) => service.services.length > 0);
            setServiceList(genericServiceList);
            // group services
            const filteredServiceList = service_cat.service_categories && 
            service_cat.service_categories.map((service) => ({
                ...service,
                services: service.services.filter((item) => item.is_group === true)
            }))
            .filter((service) => service.services.length > 0);
            setGroupServices(filteredServiceList)
        }
    }, [service_cat])
    return (
        <>
            <FixHeader />
            {
                shopLoading &&
                <>
                    <MetaGenerator
                        title="Loading"
                    />
                    <div className='g-profile-container pt-100'>
                        <div className='loader_wrap'>
                            <img src={loader} alt="chuzeday" width={180} />
                        </div>
                    </div>
                </>
            }
            {
                data &&
                <div className={business?.slider ? 'g-profile-container pt-60' : 'g-profile-container pt-100'}>
                    <MetaGenerator
                        title={business?.name}
                    />
                    {
                        business?.slider &&
                        <ShopBaner content={business?.slider} />
                    }
                    <ShopAbout content={business} />
                    {/* <Offer /> */}
                    {
                        serviceLoading &&
                        <div className='loader_wrap'>
                            <img src={loader} alt="chuzeday" width={180} />
                        </div>
                    }
                    {
                       !serviceLoading && serviceList && singleShop &&
                        <ContSerVcr.Provider value={{ serviceList, groupServices, singleShop, refetchShop, shopSlotDuration }}>
                            <ShopService />
                        </ContSerVcr.Provider>
                    }
                    {
                        business?.video_vetting &&
                        <div className="container my-2">
                            <p className='video-vetting-box p-3 mb-5 shadow'>The Business you are booking with requires that all new Guests complete a Consultation Video Call before completing the requested booking... please book a time to meet with the Business on-line using Chuzeday Video and discuss your planned service booking </p>
                        </div>
                    }
                    {
                        business?.rating > 0 &&
                        <Review rating={business?.rating} ratingNum={business?.number_of_ratings} id={business?.id} />
                    }
                    <div className="container my-5">
                        <p>Looking for more businesses near? <Link to="/">click here</Link></p>
                    </div>

                    <ShopBottom bName={business?.name} content={serviceList} group= {groupServices} cancellation={business?.cancellation} />
                </div>
            }
            <WhyChoose />
            <Footer />
        </>
    );
};

export default SingleShop;