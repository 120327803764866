import React from 'react';
import Logo from '../../../../dist/images/call-to-action/logo.svg';
import { Link } from 'react-router-dom';

const BonusLink = () => {
    return (
        <div className='call-to-action call-to-action-business mt-5 pt-2'>
            <div className="action-top mx-auto text-center">
                <a href="https://business.chuzeday.com/auth/registration" rel="noreferrer">
                    <img src={Logo} alt="Chuzeday" className='action_logo' />
                    {/* <div className='bottom-text'>1 Month Free Trial</div> */}
                    <Link 
                        // href="https://business.chuzeday.com/auth/registration" 
                        to={"/get-bonus-materials"}
                        rel="noreferrer" 
                        className='btn btn-lg bg-light mt-5'
                    >
                        Bonus Materials
                    </Link>
                </a>
            </div>
        </div>
    )
}

export default BonusLink