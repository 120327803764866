import React, { useEffect, useState } from 'react';
import MetaGenerator from '../widgets/MetaGenerator';
import { Form, Row, Col } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import { CAMPAIGN_TRAFFIC } from '../../../gql/Mutation';
import { useMutation } from '@apollo/client';
import BookingEmbed from './BookingEmbed';

const QueryPagePreRequest = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const history = useHistory();

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };
    // Function to get cookie value by name
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const referralCode = getCookie("referralCode");
    console.log("🚀 ~ QueryPagePreRequest ~ referralCode:", referralCode)
    const [campaignTraffic] = useMutation(CAMPAIGN_TRAFFIC);
    // Set referral code in cookies on component mount using vanilla JavaScript
    useEffect(() => {
        const getCookie = (name) => {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
        };
    
        const setReferralCookie = (referralCode) => {
          const d = new Date();
          d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000)); // Set the cookie to expire in 30 days
          const expires = `expires=${d.toUTCString()}`;
          document.cookie = `referralCode=${encodeURIComponent(referralCode)};${expires};path=/`;
        };
    
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('ref');
        
        if (referralCode) {
          if (!getCookie('referralCode')) {
            setReferralCookie(referralCode);
          }
        }
    }, []);
    
    useEffect(() => {
        if (referralCode) {
        campaignTraffic({
            variables: {
              page: 'query-prompt',
              referral_code: referralCode,
            },
          });
        }
      }, [referralCode, campaignTraffic]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedOption === 'email') {
            history.push('/system/guest-query/general');
        } else if (selectedOption === 'call') {
            history.push('/system/guest-query-call');
        }
    };

    return (
        <>
            <MetaGenerator title="query prompt" />
            <div className="aboutus-section">
                {/* <div className="aboutus row">
                    <div className="mailLoginWrap col-md-8 mx-auto">
                        <div className="LoginTop">
                            <span className="title">How would you like us to contact you?</span>
                            <Form className="signUpForm" onSubmit={onSubmit} autoComplete="off">
                                <div className="formInput">
                                    <p>Please tick one of the below options: </p>
                                    <Row>
                                        <Form.Group as={Col} md={12} controlId="formEmailCheckbox" className="mb-3 ps-3">
                                            <Form.Check
                                                type="radio"
                                                label="Email"
                                                value="email"
                                                checked={selectedOption === 'email'}
                                                onChange={handleRadioChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md={12} controlId="formCallheckbox" className="mb-3 ps-3">
                                            <Form.Check
                                                type="radio"
                                                label="Arrange a Call"
                                                value="call"
                                                checked={selectedOption === 'call'}
                                                onChange={handleRadioChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <button className="primaryBtn btn py-2 px-5 d-flex" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div> */}
                <BookingEmbed/>
            </div>
        </>
    );
};

export default QueryPagePreRequest;
