import React from 'react';
import ReactDOM from 'react-dom';
import App from './frontend/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-toastify/dist/ReactToastify.css';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  from,
  split
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

// Handle errors
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.forEach(({ message }) => {
      console.log(`Graphql error: ${message}`);
      if (message === "Unauthenticated.") {
        localStorage.clear();
        client.cache.reset();
        window.location.replace("/guest-login");
      }
    });
  }
  if (networkError) {
    console.log("Network error:", networkError);
  }
});

// ✅ Define base URLs based on environment
const BASE_URL = 
  window.location.host.includes("localhost:3000") ||
  window.location.host.includes("testguest.chuzeday.com")
    ? 'https://testbackend.chuzeday.com/graphql'
    : window.location.host.includes("betaguest.chuzeday.com")
      ? "https://betaadmin.chuzeday.com/graphql"
      : "https://backend.chuzeday.com/graphql";

const V2_URL = `${BASE_URL}/v2`; // ✅ Define v2 endpoint

// ✅ Create HttpLinks for v1 and v2
const defaultLink = new HttpLink({ uri: BASE_URL });
const v2Link = new HttpLink({ uri: V2_URL });

// ✅ List of operations that should use /graphql/v2
const v2Operations = [""]; // Add more as needed
// const v2Operations = ["appointment_booking"]; // Add more as needed

// ✅ Middleware to check if the operation is in v2Operations
const dynamicVersionLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName;
  return v2Operations.includes(operationName) 
    ? v2Link.request(operation, forward) 
    : defaultLink.request(operation, forward);
});

// ✅ Authentication middleware
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('guest_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// ✅ Combine all links
const link = from([
  errorLink,
  authLink.concat(dynamicVersionLink)
]);

// ✅ Create Apollo Client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  fetchOptions: {
    mode: 'no-cors',
  },
  shouldBatch: true
});

// ✅ Render React App
ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);
