import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from '../system/Loader';
import Slider from "react-slick";

//API
import { BLOGS } from '../../../gql/Query';

const BlogLink = () => {
    const imageBaseURL = "https://chuzeday.com/";
    const maxLength = 300;
    const [blog, setBlog] = useState();
    const { data, loading, error } = useQuery(BLOGS);

    //----------------------html to string-------------------
    const extractContent = (text, slug) => {
        const span = document.createElement('span');
        span.innerHTML = text;
        if (span.textContent?.length > maxLength) {
            return <p>{`${span.textContent?.substring(0, maxLength)}...`} <Link to={`/system/blog/${slug}`}>See More</Link> </p>
        } else {
            return <p> {span.textContent} </p>
        }

    };

    useEffect(() => {
        if (data) {
            setBlog(data?.blogs)
            //console.log("data", data)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);

    // Slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of cards to show
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
        <div className='container py-5 mb-5'>
            <h2 className='sec_heading text-center'>Blogs</h2>
            <div className="blog-card">
                {loading ? (
                    <Loader />
                ) : (
                    blog?.length > 0 && (
                        <Slider {...sliderSettings}>
                            {blog.map((item) => (
                                <div className="col-md-12 mb-4 slider-item" key={item.id}>
                                    <Link to={`/system/blog/${item?.slug}`}>
                                        <Card className="outer-card">
                                            <Card.Img
                                                className="card img-fluid p-0"
                                                variant="top"
                                                src={`${imageBaseURL}${item?.image}`}
                                            />
                                            <Card.Body>
                                                <h2>{item?.title}</h2>
                                                <Card.Text>
                                                    <div> {extractContent(item?.description, item?.slug)} </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    )
                )}
            </div>
        </div>
        </>
    )
}

export default BlogLink