import React, { useContext, useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { cartList } from "./ShopService";

const stripePromise = loadStripe(
  "pk_test_51KOndcBFjBhK7GuGApQRkav42TuHfIcBp1WBhRd9JJpDyWsZHl4qMsm80V0mOJQbB4TPN3sHglNlHK86rZEadeCZ00498maoYX"
);

const ApplePay = ({ amount, setGPaymentData: propSetGPaymentData, onButtonClick }) => {
  // Attempt to get the context, if available (for global state management)
  let contextSetGPaymentData;
  try {
    const context = useContext(cartList);
    if (context) {
      contextSetGPaymentData = context.setGPaymentData;
    }
  } catch (error) {
    console.warn("ApplePayment: cartList provider is not available.");
  }

  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [error, setError] = useState(null);

  // use context `setGPaymentData` if available, otherwise fallback to the props
  const setGPaymentData = contextSetGPaymentData || propSetGPaymentData;

  useEffect(() => {
    if (!stripe || !elements) return;

    const pr = stripe.paymentRequest({
      country: "IE",
      currency: "eur",
      total: {
        label: "Total",
        amount: amount, // Ensure it's in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      } else {
        setError("Apple Pay is not available in this browser");
      }
    });

    pr.on("paymentmethod", async (e) => {
      try {
        console.log("✅ Payment method received:", e.paymentMethod);
        setGPaymentData(e.paymentMethod);
        e.complete("success");
      } catch (err) {
        console.error("🚨 Unexpected error:", err);
        e.complete("fail");
      }
    });

    return () => {
      pr.removeAllListeners(); // Cleanup event listeners
    };
  }, [stripe, elements, amount, setGPaymentData]);

  return (
    <div className="flex justify-center" onClick={onButtonClick}>
      {paymentRequest ? (
        <div className="">
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  type: "book",
                  theme: "dark",
                  height: "50px",
                  fontSize: "15px",
                  borderRadius: "10px",
                },
              },
            }}
          />
        </div>
      ) : (
        <span className="text-gray-500 mt-4">
          Loading apple payment options...
        </span>
      )}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default function ApplePayWrapper({ amount, onButtonClick, setGPaymentData }) {
  return (
    <Elements stripe={stripePromise}>
      <ApplePay
        amount={amount * 100}
        onButtonClick={onButtonClick}
        setGPaymentData={setGPaymentData}
      />
    </Elements>
  );
}
