import React, { useState } from 'react';
import styled from 'styled-components';
import MobileCategory from './MobileCategory';
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { Link } from 'react-router-dom';

const StyledBurger = styled.div`
  width: 2rem;
  height: 25px;
  position: fixed;
  top: 14px;
  right: 20px;
  z-index: 20;
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: absolute;
    cursor:pointer;
  }
  div {
    width: 24px;
    height: 3px;
    background-color: ${({ open }) => open ? '#ebc11a' : '#ebc11a'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      {/* <MobileCategory open={open} setOpen={setOpen} /> */}

      {/* <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant={open ? "danger" : "primary"} // Change button color when open
      >
        {open ? "✖ Close" : "☰ Open"}
      </Button> */}
      <Collapse in={open}>
        <div id="example-collapse-text" className="collapse_content">
          <ul>
            <li>
              <Link to="/system/faq" className="d-block mb-3" onClick={() => setOpen(!open)}>FAQ</Link>
            </li>
            <li><Link to="/system/about" className="d-block mb-3" onClick={() => setOpen(!open)}>About</Link></li>
            <li><Link to="/system/blog" className="d-block mb-3" onClick={() => setOpen(!open)}>Blog</Link></li>
            <li><Link to="/how-it-works" className="d-block mb-3">How It Works</Link></li>
            <li><Link to="/system/book-a-demo" className="d-block mb-3">Book a demo</Link></li>
          </ul>
        </div>
      </Collapse>
    </>
  )
}

export default Burger;