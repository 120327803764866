import React from 'react';
import { Rating } from '@mui/material';
import { Link } from 'react-router-dom';

const AdditionalFeature = () => {
    const features = [
        {
            subtitle: "Say goodbye to chasing overdue invoices",
            title: "Streamlined Payments",
            description: "Ensure timely payments by accepting deposits or full fees upfront, helping you maintain a steady cash flow and focus on your clients.",
            testimonial: {
                text: "With streamlined payments, I’ve eliminated overdue invoices and can dedicate my energy to delivering excellent service",
                author: "Nikki McGovern",
                company: "Massage and Pilates by Nikki",
                image: "/nikki.png",
                rating: 5
            },
            image: "/analytics.jpg"
        },
        {
            subtitle: "Turn data into decisions",
            title: "Actionable Insights with Analytics",
            description: "Track and analyze your payment trends to improve cash flow, reduce no-shows, and identify key opportunities for growth",
            testimonial: {
                text: "The analytics feature helps me understand my clients' payment patterns, allowing me to make smarter financial decisions.",
                author: "Winnie Rath",
                company: "The Renee Studio Wexford",
                image: "/winnie.jpg",
                rating: 5
            },
            image: "/bonus.jpg"
        },
        {
            subtitle: "Stay stocked and stress-free",
            title: "Smart Inventory Management",
            description: "Track inventory in real time, reduce waste, and ensure you always have what your clients need, when they need it",
            testimonial: {
                text: "Managing my inventory is no longer a guessing game—this system keeps everything organized and efficient..",
                author: "Michelle Gordon",
                company: "The Fitting Room @ New Mood",
                image: "/Sinead.jpg",
                rating: 5
            },
            image: "/accountary.jpg"
        }
    ];

    return (
        <section className="features_section py-5">
            {features.map((feature, index) => (
                <div key={index} className="container mb-5">
                    <div className="row align-items-center gx-5 mt-5 pt-5">
                        {/* Left Content */}
                        <div className={`col-lg-6 ${index === 1 ? 'order-lg-2' : ''}`} data-aos="fade-left" >
                            <div className="feature_text">
                                <h6 className="mb-4">
                                    {feature.subtitle}
                                </h6>

                                <h2 className="display-4 fw-bold mb-4">
                                    {feature.title}
                                </h2>

                                <p className="text-secondary mb-4 short_desc">
                                    {feature.description}
                                </p>

                                {/* Testimonial Card */}
                                <div className="d-flex gap-4 align-items-start p-4 review_card">
                                    <div className="flex-shrink-0 reviewer">
                                        <img
                                            src={feature.testimonial.image}
                                            alt={feature.testimonial.author}
                                            className="rounded-circle"
                                            width="80"
                                            height="80"
                                        />
                                    </div>

                                    <div className="review_text">
                                        <p className="fst-italic text-secondary mb-2">
                                            "{feature.testimonial.text}"
                                        </p>

                                        <div className="d-flex gap-1 mb-2 rating_stars">
                                            <Rating
                                                value={feature.testimonial.rating}
                                                name="half-rating"
                                                precision={0.1}
                                                readOnly
                                            />
                                        </div>

                                        <div>
                                            <strong className="d-block">- {feature.testimonial.author}</strong>
                                            <span className="text-secondary">({feature.testimonial.company})</span>
                                        </div>
                                    </div>
                                </div>

                                {/* <Link to="/system/book-a-demo">
                                    <button className="btn btn-dark btn-lg mb-4 rounded-pill round_btn">
                                        Learn More
                                    </button>
                                </Link> */}
                            </div>
                        </div>

                        {/* Right Content - Feature Image */}
                        <div className={`col-lg-6 ftr_img_wrap ${index === 1 ? 'order-lg-1 ftr_img_1' : ''}`} data-aos="fade-right">
                            <div className="position-relative">
                                <img
                                    src={feature.image}
                                    alt={feature.title}
                                    className="img-fluid feature_img mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default AdditionalFeature;